export default {
  headers: [
    {
      id: 'main-header-ciovo',
      logo: {
        alt: 'premier homes logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/ciovo/logo.png',
        url: 'https://www.greatersandiegoareahomes.com/',
      },
      breakpoint: 1399,
      desktopMenu: {
        type: 'default',
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-ciovo-desktop-header-logo-container',
          imageClassName: 'on-ciovo-desktop-header-logo',
        },
        headerClassName: 'on-ciovo-desktop-header',
        headerSectionsClassName: 'on-ciovo-desktop-header-sections',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '' || null,
        itemClassName: 'on-ciovo-desktop-item',
        menuClassName: 'on-ciovo-desktop-menu',
        menuLabelActiveClassName: 'on-ciovo-active-label',
        menuItemClassName: 'on-ciovo-desktop-item-folder',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'on-ciovo-header-home-item',
            content: 'HOME',
            url: 'https://www.greatersandiegoareahomes.com/',
          },
          {
            id: 'on-ciovo-header-buying-item',
            content: 'BUYING',
            menuItems: [
              {
                id: 'on-ciovo-header-buying-listings',
                content: 'All Listings',
                url: 'https://www.greatersandiegoareahomes.com/listing',
              },
              {
                id: 'on-ciovo-header-buying-map-search',
                content: 'Map Search',
                url: 'https://www.greatersandiegoareahomes.com/map-search',
              },
              {
                id: 'on-ciovo-header-buying-new-construction',
                content: 'New Construction',
                url: 'https://www.greatersandiegoareahomes.com/new-homes-san-diego',
              },
              {
                id: 'on-ciovo-header-buying-why-buy',
                content: 'Why Buy With Our Team',
                url: 'https://www.greatersandiegoareahomes.com/about/buyer',
              },
              {
                id: 'on-ciovo-header-buying-mortgage',
                content: 'Mortgage Calculator',
                url: 'https://www.greatersandiegoareahomes.com/mortgage-calculator',
              },
              {
                id: 'on-ciovo-header-buying-affordability',
                content: 'Affordability Calculator',
                url: 'https://www.greatersandiegoareahomes.com/affordability-calculator',
              },
            ],
          },
          {
            id: 'on-ciovo-header-selling-item',
            content: 'SELLING',
            menuItems: [
              {
                id: 'on-ciovo-header-selling-',
                content: 'Sell Your Home',
                url: 'https://www.greatersandiegoareahomes.com/about/seller',
              },
              {
                id: 'on-ciovo-header-selling-guaranteed-cash',
                content: 'Guaranteed Cash Offer',
                url: 'https://www.greatersandiegoareahomes.com/guaranteed-cash-offer',
              },
              {
                id: 'on-ciovo-header-selling-home-eval',
                content: 'Home Evaluation',
                url: 'https://www.greatersandiegoareahomes.com/evaluation',
              },
              {
                id: 'on-ciovo-header-selling-sold-listings',
                content: 'Sold Listings',
                url: 'https://www.greatersandiegoareahomes.com/sold-listing',
              },
            ],
          },
          {
            id: 'on-ciovo-header-neighborhoods-item',
            content: 'NEIGHBORHOODS',
            menuItems: [
              {
                id: 'on-ciovo-header-neighborhoods-4s-ranch',
                url: 'https://www.greatersandiegoareahomes.com/4s-ranch-homes-for-sale/156520410/4s-ranch',
                content: '4S Ranch',
              },
              {
                id: 'on-ciovo-header-neighborhoods-alpine',
                url: 'https://www.greatersandiegoareahomes.com/alpine-homes-for-sale/143923784/alpine',
                content: 'Alpine',
              },
              {
                id: 'on-ciovo-header-neighborhoods-park-west',
                url: 'https://www.greatersandiegoareahomes.com/bankers-hill-san-diego/155719708/park-west',
                content: 'Park West',
              },
              {
                id: 'on-ciovo-header-neighborhoods-cardiff-by-the-sea',
                url: 'https://www.greatersandiegoareahomes.com/cardiff-by-the-sea-homes-for-sale',
                content: 'Cardiff by the Sea',
              },
              {
                id: 'on-ciovo-header-neighborhoods-carmel-valley',
                url: 'https://www.greatersandiegoareahomes.com/carmel-valley-san-diego/156101255/carmel-valley',
                content: 'Carmel Valley',
              },
              {
                id: 'on-ciovo-header-neighborhoods-coronado',
                url: 'https://www.greatersandiegoareahomes.com/coronado-homes-for-sale',
                content: 'Coronado',
              },
              {
                id: 'on-ciovo-header-neighborhoods-del-mar',
                url: 'https://www.greatersandiegoareahomes.com/del-mar-homes-for-sale',
                content: 'Del Mar',
              },
              {
                id: 'on-ciovo-header-neighborhoods-downtown-san-diego',
                content: 'Downtown San Diego',
                menuItems: [
                  {
                    id: 'on-ciovo-header-neighborhoods-cortez',
                    url: 'https://www.greatersandiegoareahomes.com/cortez-hill-condos/153972934/cortez',
                    content: 'Cortez',
                  },
                  {
                    id: 'on-ciovo-header-neighborhoods-core-columbia',
                    url: 'https://www.greatersandiegoareahomes.com/core-columbia-district-condos/153972935/core-columbia',
                    content: 'Core-Columbia',
                  },
                  {
                    id: 'on-ciovo-header-neighborhoods-east-village',
                    url: 'https://www.greatersandiegoareahomes.com/east-village-condos/153972933/east-village',
                    content: 'East Village',
                  },
                  {
                    id: 'on-ciovo-header-neighborhoods-gaslamp',
                    url: 'https://www.greatersandiegoareahomes.com/gaslamp-san-diego-condos/153972932/gaslamp',
                    content: 'Gaslamp',
                  },
                  {
                    id: 'on-ciovo-header-neighborhoods-little-italy',
                    url: 'https://www.greatersandiegoareahomes.com/little-italy-condos/153972931/little-italy',
                    content: 'Little Italy',
                  },
                  {
                    id: 'on-ciovo-header-neighborhoods-marina',
                    url: 'https://www.greatersandiegoareahomes.com/marina-district-condos/153972930/marina',
                    content: 'Marina',
                  },
                ],
              },
              {
                id: 'on-ciovo-header-neighborhoods-eastlake',
                url: 'https://www.greatersandiegoareahomes.com/eastlake-chula-vista/156101569/eastlake',
                content: 'Eastlake',
              },
              {
                id: 'on-ciovo-header-neighborhoods-encinitas',
                url: 'https://www.greatersandiegoareahomes.com/encinitas-homes-for-sale',
                content: 'Encinitas',
              },
              {
                id: 'on-ciovo-header-neighborhoods-fairbanks-ranch',
                url: 'https://www.greatersandiegoareahomes.com/fairbanks-ranch-san-diego/156101271/fairbanks-ranch',
                content: 'Fairbanks Ranch',
              },
              {
                id: 'on-ciovo-header-neighborhoods-hillcrest',
                url: 'https://www.greatersandiegoareahomes.com/hillcrest-san-diego/155770868/hillcrest',
                content: 'Hillcrest',
              },
              {
                id: 'on-ciovo-header-neighborhoods-kensington',
                url: 'https://www.greatersandiegoareahomes.com/kensington-san-diego/156100687/kensington',
                content: 'Kensington',
              },
              {
                id: 'on-ciovo-header-neighborhoods-la-jolla',
                url: 'https://www.greatersandiegoareahomes.com/la-jolla-homes-for-sale',
                content: 'La Jolla',
              },
              {
                id: 'on-ciovo-header-neighborhoods-mission-hills',
                url: 'https://www.greatersandiegoareahomes.com/mission-hills-san-diego/155770882/mission-hills',
                content: 'Mission Hills',
              },
              {
                id: 'on-ciovo-header-neighborhoods-mission-beach',
                url: 'https://www.greatersandiegoareahomes.com/mission-beach-san-diego/143923797/mission-beach',
                content: 'Mission Beach',
              },
              {
                id: 'on-ciovo-header-neighborhoods-ocean-beach',
                url: 'https://www.greatersandiegoareahomes.com/ocean-beach-homes-for-sale',
                content: 'Ocean Beach (OB)',
              },
              {
                id: 'on-ciovo-header-neighborhoods-pacific-beach',
                url: 'https://www.greatersandiegoareahomes.com/neighborhood-pacific-beach/143923796/pacific-beach',
                content: 'Pacific Beach',
              },
              {
                id: 'on-ciovo-header-neighborhoods-point-loma',
                url: 'https://www.greatersandiegoareahomes.com/point-loma-homes-for-sale',
                content: 'Point Loma',
              },
              {
                id: 'on-ciovo-header-neighborhoods-rancho-bernardo',
                url: 'https://www.greatersandiegoareahomes.com/rancho-bernardo-golf-course-homes',
                content: 'Rancho Bernardo Golf Course Homes',
              },
              {
                id: 'on-ciovo-header-neighborhoods-rancho-santa-fe',
                url: 'https://www.greatersandiegoareahomes.com/rancho-santa-fe-homes-for-sale',
                content: 'Rancho Santa Fe',
              },
              {
                id: 'on-ciovo-header-neighborhoods-solana-beach',
                url: 'https://www.greatersandiegoareahomes.com/solana-beach/156100719/solana-beach',
                content: 'Solana Beach',
              },
              {
                id: 'on-ciovo-header-neighborhoods-torrey-hills',
                url: 'https://www.greatersandiegoareahomes.com/torrey-hills-san-diego/156101296/torrey-hills',
                content: 'Torrey Hills',
              },
            ],
          },
          {
            id: 'on-ciovo-header-exclusive-trade-in-item',
            url: 'http://trade-in.greatersandiegoareahomes.com/',
            content: 'EXCLUSIVE TRADE-IN PROGRAM',
          },
          {
            id: 'on-ciovo-header-exclusive-cash-offer-item',
            url: 'https://cash-offer-ciovo.netlify.app/',
            content: 'EXCLUSIVE CASH OFFER PROGRAM',
          },
          {
            id: 'on-ciovo-header-blogs-item',
            url: 'https://www.greatersandiegoareahomes.com/blog',
            content: 'BLOGS',
          },
          {
            id: 'on-ciovo-header-about-item',
            content: 'ABOUT',
            menuItems: [
              {
                id: 'on-ciovo-header-about-us',
                url: 'https://www.greatersandiegoareahomes.com/about',
                content: 'About Us',
              },
              {
                id: 'on-ciovo-header-about-reviews',
                url: 'https://www.greatersandiegoareahomes.com/reviews',
                content: 'Reviews',
              },
              {
                id: 'on-ciovo-header-about-contact-us',
                url: 'https://www.greatersandiegoareahomes.com/contact',
                content: 'Contact Us',
              },
            ],
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-ciovo-mheader-logo-container',
          imageClassName: 'on-ciovo-mheader-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-ciovo-hamburger',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-ciovo-mheader',
        headerSectionsClassName: 'on-ciovo-mheader-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-ciovo-mheader-menu',
        itemClassName: 'on-ciovo-mheader-item',
        menuClassName: 'on-ciovo-mheader-folder',
        menuLabelActiveClassName: 'on-ciovo-mheader-label-active',
        menuItemClassName: 'on-ciovo-mheader-folder-item',
        subMenuClassName: 'on-ciovo-mheader-submenu',
        subMenuItemClassName: 'on-ciovo-mheader-submenu-item',
        subMenuLabelActiveClassName: 'on-ciovo-mheader-submenu-item-active',
        headerItems: [],
        items: [
          {
            id: 'on-ciovo-header-mobile-home-item',
            content: 'HOME',
            url: 'https://www.greatersandiegoareahomes.com/',
          },
          {
            id: 'on-ciovo-header-mobile-buying-item',
            content: 'BUYING',
            menuItems: [
              {
                id: 'on-ciovo-header-mobile-buying-listings',
                content: 'All Listings',
                url: 'https://www.greatersandiegoareahomes.com/listing',
              },
              {
                id: 'on-ciovo-header-mobile-buying-map-search',
                content: 'Map Search',
                url: 'https://www.greatersandiegoareahomes.com/map-search',
              },
              {
                id: 'on-ciovo-header-mobile-buying-new-construction',
                content: 'New Construction',
                url: 'https://www.greatersandiegoareahomes.com/new-homes-san-diego',
              },
              {
                id: 'on-ciovo-header-mobile-buying-why-buy',
                content: 'Why Buy With Our Team',
                url: 'https://www.greatersandiegoareahomes.com/about/buyer',
              },
              {
                id: 'on-ciovo-header-mobile-buying-mortgage',
                content: 'Mortgage Calculator',
                url: 'https://www.greatersandiegoareahomes.com/mortgage-calculator',
              },
              {
                id: 'on-ciovo-header-mobile-buying-affordability',
                content: 'Affordability Calculator',
                url: 'https://www.greatersandiegoareahomes.com/affordability-calculator',
              },
            ],
          },
          {
            id: 'on-ciovo-header-mobile-selling-item',
            content: 'SELLING',
            menuItems: [
              {
                id: 'on-ciovo-header-mobile-selling-',
                content: 'Sell Your Home',
                url: 'https://www.greatersandiegoareahomes.com/about/seller',
              },
              {
                id: 'on-ciovo-header-mobile-selling-guaranteed-cash',
                content: 'Guaranteed Cash Offer',
                url: 'https://www.greatersandiegoareahomes.com/guaranteed-cash-offer',
              },
              {
                id: 'on-ciovo-header-mobile-selling-home-eval',
                content: 'Home Evaluation',
                url: 'https://www.greatersandiegoareahomes.com/evaluation',
              },
              {
                id: 'on-ciovo-header-mobile-selling-sold-listings',
                content: 'Sold Listings',
                url: 'https://www.greatersandiegoareahomes.com/sold-listing',
              },
            ],
          },
          {
            id: 'on-ciovo-header-mobile-neighborhoods-item',
            content: 'NEIGHBORHOODS',
            menuItems: [
              {
                id: 'on-ciovo-header-mobile-neighborhoods-4s-ranch',
                url: 'https://www.greatersandiegoareahomes.com/4s-ranch-homes-for-sale/156520410/4s-ranch',
                content: '4S Ranch',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-alpine',
                url: 'https://www.greatersandiegoareahomes.com/alpine-homes-for-sale/143923784/alpine',
                content: 'Alpine',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-park-west',
                url: 'https://www.greatersandiegoareahomes.com/bankers-hill-san-diego/155719708/park-west',
                content: 'Park West',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-cardiff-by-the-sea',
                url: 'https://www.greatersandiegoareahomes.com/cardiff-by-the-sea-homes-for-sale',
                content: 'Cardiff by the Sea',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-carmel-valley',
                url: 'https://www.greatersandiegoareahomes.com/carmel-valley-san-diego/156101255/carmel-valley',
                content: 'Carmel Valley',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-coronado',
                url: 'https://www.greatersandiegoareahomes.com/coronado-homes-for-sale',
                content: 'Coronado',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-del-mar',
                url: 'https://www.greatersandiegoareahomes.com/del-mar-homes-for-sale',
                content: 'Del Mar',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-downtown-san-diego',
                content: 'Downtown San Diego',
                menuItems: [
                  {
                    id: 'on-ciovo-header-mobile-neighborhoods-cortez',
                    url: 'https://www.greatersandiegoareahomes.com/cortez-hill-condos/153972934/cortez',
                    content: 'Cortez',
                  },
                  {
                    id: 'on-ciovo-header-mobile-neighborhoods-core-columbia',
                    url: 'https://www.greatersandiegoareahomes.com/core-columbia-district-condos/153972935/core-columbia',
                    content: 'Core-Columbia',
                  },
                  {
                    id: 'on-ciovo-header-mobile-neighborhoods-east-village',
                    url: 'https://www.greatersandiegoareahomes.com/east-village-condos/153972933/east-village',
                    content: 'East Village',
                  },
                  {
                    id: 'on-ciovo-header-mobile-neighborhoods-gaslamp',
                    url: 'https://www.greatersandiegoareahomes.com/gaslamp-san-diego-condos/153972932/gaslamp',
                    content: 'Gaslamp',
                  },
                  {
                    id: 'on-ciovo-header-mobile-neighborhoods-little-italy',
                    url: 'https://www.greatersandiegoareahomes.com/little-italy-condos/153972931/little-italy',
                    content: 'Little Italy',
                  },
                  {
                    id: 'on-ciovo-header-mobile-neighborhoods-marina',
                    url: 'https://www.greatersandiegoareahomes.com/marina-district-condos/153972930/marina',
                    content: 'Marina',
                  },
                ],
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-eastlake',
                url: 'https://www.greatersandiegoareahomes.com/eastlake-chula-vista/156101569/eastlake',
                content: 'Eastlake',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-encinitas',
                url: 'https://www.greatersandiegoareahomes.com/encinitas-homes-for-sale',
                content: 'Encinitas',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-fairbanks-ranch',
                url: 'https://www.greatersandiegoareahomes.com/fairbanks-ranch-san-diego/156101271/fairbanks-ranch',
                content: 'Fairbanks Ranch',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-hillcrest',
                url: 'https://www.greatersandiegoareahomes.com/hillcrest-san-diego/155770868/hillcrest',
                content: 'Hillcrest',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-kensington',
                url: 'https://www.greatersandiegoareahomes.com/kensington-san-diego/156100687/kensington',
                content: 'Kensington',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-la-jolla',
                url: 'https://www.greatersandiegoareahomes.com/la-jolla-homes-for-sale',
                content: 'La Jolla',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-mission-hills',
                url: 'https://www.greatersandiegoareahomes.com/mission-hills-san-diego/155770882/mission-hills',
                content: 'Mission Hills',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-mission-beach',
                url: 'https://www.greatersandiegoareahomes.com/mission-beach-san-diego/143923797/mission-beach',
                content: 'Mission Beach',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-ocean-beach',
                url: 'https://www.greatersandiegoareahomes.com/ocean-beach-homes-for-sale',
                content: 'Ocean Beach (OB)',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-pacific-beach',
                url: 'https://www.greatersandiegoareahomes.com/neighborhood-pacific-beach/143923796/pacific-beach',
                content: 'Pacific Beach',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-point-loma',
                url: 'https://www.greatersandiegoareahomes.com/point-loma-homes-for-sale',
                content: 'Point Loma',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-rancho-bernardo',
                url: 'https://www.greatersandiegoareahomes.com/rancho-bernardo-golf-course-homes',
                content: 'Rancho Bernardo Golf Course Homes',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-rancho-santa-fe',
                url: 'https://www.greatersandiegoareahomes.com/rancho-santa-fe-homes-for-sale',
                content: 'Rancho Santa Fe',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-solana-beach',
                url: 'https://www.greatersandiegoareahomes.com/solana-beach/156100719/solana-beach',
                content: 'Solana Beach',
              },
              {
                id: 'on-ciovo-header-mobile-neighborhoods-torrey-hills',
                url: 'https://www.greatersandiegoareahomes.com/torrey-hills-san-diego/156101296/torrey-hills',
                content: 'Torrey Hills',
              },
            ],
          },
          {
            id: 'on-ciovo-header-mobile-exclusive-trade-in-item',
            url: 'http://trade-in.greatersandiegoareahomes.com/',
            content: 'EXCLUSIVE TRADE-IN PROGRAM',
          },
          {
            id: 'on-ciovo-header-mobile-exclusive-cash-offer-item',
            url: 'https://cash-offer-ciovo.netlify.app/',
            content: 'EXCLUSIVE CASH OFFER PROGRAM',
          },
          {
            id: 'on-ciovo-header-mobile-blogs-item',
            url: 'https://www.greatersandiegoareahomes.com/blog',
            content: 'BLOGS',
          },
          {
            id: 'on-ciovo-header-mobile-about-item',
            content: 'ABOUT',
            menuItems: [
              {
                id: 'on-ciovo-header-mobile-about-us',
                url: 'https://www.greatersandiegoareahomes.com/about',
                content: 'About Us',
              },
              {
                id: 'on-ciovo-header-mobile-about-reviews',
                url: 'https://www.greatersandiegoareahomes.com/reviews',
                content: 'Reviews',
              },
              {
                id: 'on-ciovo-header-mobile-about-contact-us',
                url: 'https://www.greatersandiegoareahomes.com/contact',
                content: 'Contact Us',
              },
            ],
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-ciovo-footer-first',
      display: true,
      className: 'on-ciovo-footer-section on-ciovo-footer-section-first',
      sectionClassName: 'on-ciovo-footer-section-container',
      columns: [
        {
          id: 'on-ciovo-footer-first-1',
          className: 'on-ciovo-footer-disclaimer',
          style: {},
          items: [
            {
              id: 'on-ciovo-footer-first-1-1',
              type: 'image',
              className: 'on-ciovo-footer-img',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/ciovo/logo-footer-san-diego.png',
              style: {},
            },
            {
              id: 'on-ciovo-footer-first-1-2',
              type: 'heading',
              className: 'on-ciovo-footer-text pt-1 pb-2',
              elementType: 'p',
              data: 'This information is deemed reliable but not guaranteed. You should rely on this information only to decide whether or not to further investigate a particular property. BEFORE MAKING ANY OTHER DECISION, YOU SHOULD PERSONALLY INVESTIGATE THE FACTS (e.g. square footage and lot size) with the assistance of an appropriate professional. You may use this information only to identify properties you may be interested in investigating further. All uses except for personal, non-commercial use in accordance with the foregoing purpose are prohibited. Redistribution or copying of this information, any photographs or video tours is strictly prohibited. This information is derived from the Internet Data Exchange (IDX) service provided by San Diego MLS®. Displayed property listings may be held by a brokerage firm other than the broker and/or agent responsible for this display. The information and any photographs and video tours and the compilation from which they are derived is protected by copyright. Compilation © 2022 San Diego MLS®, Inc.',
            },
            {
              id: 'on-ciovo-footer-first-1-4',
              type: 'heading',
              className: 'on-ciovo-footer-text pt-3',
              elementType: 'p',
              style: {},
              data: 'IDX information is provided exclusively for consumers’ personal, non-commercial use and that it may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Information deemed reliable but not guaranteed to be accurate. Listing information updated daily.',
            },
          ],
        },
      ],
    },
    {
      id: 'on-ciovo-footer-second',
      display: true,
      className: 'on-ciovo-footer-section on-ciovo-footer-section-second',
      sectionClassName: 'on-ciovo-footer-section-container-second',
      columns: [
        {
          id: 'ciovo-footer-first-col',
          className: 'on-ciovo-footer-col-one',
          style: {},
          items: [
            {
              id: 'ciovo-footer-first-col-one',
              type: 'image',
              className: 'on-ciovo-footer-img',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/ciovo/logo-footer-bigblock.png',
              style: {},
            },
            {
              id: 'ciovo-footer-first-col-two',
              type: 'heading',
              className: 'on-ciovo-footer-heading-premier-homes  pt-3',
              data: 'Premier Homes Team, a division of Big Block Realty',
            },
            {
              id: 'ciovo-footer-first-col-three',
              type: 'heading',
              className: 'on-ciovo-footer-heading-glen',
              style: {},
              data: 'Glen Henderson',
            },
            {
              id: 'ciovo-footer-first-col-four',
              type: 'heading',
              className: 'on-ciovo-footer-heading-ca-bre',
              style: {},
              data: 'CA BRE # 01384181',
            },
            {
              id: 'ciovo-footer-first-col-five',
              type: 'itemContent',
              className: 'on-ciovo-footer-item-content',
              style: {},
              items: [
                {
                  content: '619-500-3222',
                  url: 'tel:6195003222',
                  className: 'on-ciovo-footer-phone',
                },
                {
                  content: 'info@phtsd.com',
                  url: 'mailto:info@phtsd.com',
                  className: 'on-ciovo-footer-email',
                },
              ],
            },
            {
              id: 'ciovo-footer-first-col-seven',
              type: 'heading',
              className: 'on-ciovo-footer-heading-address',
              style: {},
              data: '2820 Camino del Rio S #314, San Diego, CA 92108',
            },
            {
              id: 'ciovo-footer-first-col-six',
              type: 'socialIcons',
              itemClassName: 'on-ciovo-footer-socials pt-1',
              style: {},
              items: [
                {
                  id: 'on-ciovo-face',
                  url: 'https://www.facebook.com/san.diego.realtors.glen.henderson/',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                },
                {
                  id: 'on-ciovo-linked',
                  url: 'https://www.linkedin.com/in/glenhenderson/',
                  iconTypeClassName: 'fab',
                  iconClassName: 'linkedin-in',
                },
                {
                  id: 'on-ciovo-twitter',
                  url: 'https://twitter.com/glenhendersonsd',
                  iconTypeClassName: 'fab',
                  iconClassName: 'twitter',
                },
                {
                  id: 'on-ciovo-zillow',
                  className: 'on-ciovo-footer-socials on-footer-ciovo-zillow',
                  iconUrl: 'https://si-homelight.s3.amazonaws.com/sites/ciovo/zillow.svg',
                  url: 'https://www.zillow.com/profile/Glen-Henderson/',
                },
                {
                  id: 'on-ciovo-yelp',
                  url: 'https://www.yelp.com/biz/glen-henderson-and-shannon-keatley-big-block-realty-san-diego',
                  iconTypeClassName: 'fab',
                  iconClassName: 'yelp',
                },
                {
                  id: 'on-ciovo-insta',
                  url: 'https://www.facebook.com/san.diego.realtors.glen.henderson/',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                },
                {
                  id: 'on-ciovo-youtube',
                  url: 'https://www.youtube.com/user/MyAllianceGroup',
                  iconTypeClassName: 'fab',
                  iconClassName: 'youtube',
                },
                {
                  id: 'on-ciovo-pinterest',
                  url: 'https://www.pinterest.com/myalliancegroup/',
                  iconTypeClassName: 'fab',
                  iconClassName: 'pinterest-p',
                },
                {
                  id: 'on-ciovo-google-plus-g',
                  url: 'https://goo.gl/maps/8y1HwekwAuUEYx8h8',
                  iconTypeClassName: 'fab',
                  iconClassName: 'google-plus-g',
                },
              ],
            },
          ],
        },
        {
          id: 'on-ciovo-footer-two-col-two',
          className: 'on-ciovo-footer-col-two',
          items: [
            {
              id: 'ciovo-footer-main-menu',
              type: 'menu',
              className: 'on-ciovo-footer-menu',
              itemClassName: 'on-ciovo-footer-menu-item',
              items: [
                {
                  id: 'ciovo-footer-menu-item-home',
                  data: 'HOME',
                  url: 'https://www.greatersandiegoareahomes.com/',
                },
                {
                  id: 'ciovo-footer-menu-item-buying',
                  data: 'BUYING',
                  submenuClassName: '',
                  submenuItemsClassName: 'on-ciovo-submenu-items',
                  submenuLabelClassName: 'on-ciovo-submenu-label',
                  submenuItems: [
                    {
                      id: 'on-ciovo-footer-buying-listings',
                      data: 'All Listings',
                      url: 'https://www.greatersandiegoareahomes.com/listing',
                    },
                    {
                      id: 'on-ciovo-footer-buying-map-search',
                      data: 'Map Search',
                      url: 'https://www.greatersandiegoareahomes.com/map-search',
                    },
                    {
                      id: 'on-ciovo-footer-buying-new-construction',
                      data: 'New Construction',
                      url: 'https://www.greatersandiegoareahomes.com/new-homes-san-diego',
                    },
                    {
                      id: 'on-ciovo-footer-buying-why-buy',
                      data: 'Why Buy With Our Team',
                      url: 'https://www.greatersandiegoareahomes.com/about/buyer',
                    },
                    {
                      id: 'on-ciovo-footer-buying-mortgage',
                      data: 'Mortgage Calculator',
                      url: 'https://www.greatersandiegoareahomes.com/mortgage-calculator',
                    },
                    {
                      id: 'on-ciovo-footer-buying-affordability',
                      data: 'Affordability Calculator',
                      url: 'https://www.greatersandiegoareahomes.com/affordability-calculator',
                    },
                  ],
                },
                {
                  id: 'footer-menu-item-selling',
                  data: 'SELLING',
                  submenuClassName: '',
                  submenuItemsClassName: 'on-ciovo-submenu-items',
                  submenuLabelClassName: 'on-ciovo-submenu-label',
                  submenuItems: [
                    {
                      id: 'on-ciovo-footer-selling-',
                      data: 'Sell Your Home',
                      url: 'https://www.greatersandiegoareahomes.com/about/seller',
                    },
                    {
                      id: 'on-ciovo-footer-selling-guaranteed-cash',
                      data: 'Guaranteed Cash Offer',
                      url: 'https://www.greatersandiegoareahomes.com/guaranteed-cash-offer',
                    },
                    {
                      id: 'on-ciovo-footer-selling-home-eval',
                      data: 'Home Evaluation',
                      url: 'https://www.greatersandiegoareahomes.com/evaluation',
                    },
                    {
                      id: 'on-ciovo-footer-selling-sold-listings',
                      data: 'Sold Listings',
                      url: 'https://www.greatersandiegoareahomes.com/sold-listing',
                    },
                  ],
                },
                {
                  id: 'footer-menu-item-neighborhoods',
                  data: 'NEIGHBORHOODS',
                  submenuClassName: '',
                  submenuItemsClassName: 'on-ciovo-submenu-items',
                  submenuLabelClassName: 'on-ciovo-submenu-label',
                  submenuItems: [
                    {
                      id: 'on-ciovo-footer-neighborhoods-4s-ranch',
                      url: 'https://www.greatersandiegoareahomes.com/4s-ranch-homes-for-sale/156520410/4s-ranch',
                      data: '4S Ranch',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-alpine',
                      url: 'https://www.greatersandiegoareahomes.com/alpine-homes-for-sale/143923784/alpine',
                      data: 'Alpine',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-park-west',
                      url: 'https://www.greatersandiegoareahomes.com/bankers-hill-san-diego/155719708/park-west',
                      data: 'Park West',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-cardiff-by-the-sea',
                      url: 'https://www.greatersandiegoareahomes.com/cardiff-by-the-sea-homes-for-sale',
                      data: 'Cardiff by the Sea',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-carmel-valley',
                      url: 'https://www.greatersandiegoareahomes.com/carmel-valley-san-diego/156101255/carmel-valley',
                      data: 'Carmel Valley',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-coronado',
                      url: 'https://www.greatersandiegoareahomes.com/coronado-homes-for-sale',
                      data: 'Coronado',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-del-mar',
                      url: 'https://www.greatersandiegoareahomes.com/del-mar-homes-for-sale',
                      data: 'Del Mar',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-downtown-san-diego',
                      url: 'https://www.greatersandiegoareahomes.com/cortez-hill-condos/153972934/cortez',
                      data: 'Downtown San Diego',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-eastlake',
                      url: 'https://www.greatersandiegoareahomes.com/eastlake-chula-vista/156101569/eastlake',
                      data: 'Eastlake',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-encinitas',
                      url: 'https://www.greatersandiegoareahomes.com/encinitas-homes-for-sale',
                      data: 'Encinitas',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-fairbanks-ranch',
                      url: 'https://www.greatersandiegoareahomes.com/fairbanks-ranch-san-diego/156101271/fairbanks-ranch',
                      data: 'Fairbanks Ranch',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-hillcrest',
                      url: 'https://www.greatersandiegoareahomes.com/hillcrest-san-diego/155770868/hillcrest',
                      data: 'Hillcrest',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-kensington',
                      url: 'https://www.greatersandiegoareahomes.com/kensington-san-diego/156100687/kensington',
                      data: 'Kensington',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-la-jolla',
                      url: 'https://www.greatersandiegoareahomes.com/la-jolla-homes-for-sale',
                      data: 'La Jolla',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-mission-hills',
                      url: 'https://www.greatersandiegoareahomes.com/mission-hills-san-diego/155770882/mission-hills',
                      data: 'Mission Hills',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-mission-beach',
                      url: 'https://www.greatersandiegoareahomes.com/mission-beach-san-diego/143923797/mission-beach',
                      data: 'Mission Beach',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-ocean-beach',
                      url: 'https://www.greatersandiegoareahomes.com/ocean-beach-homes-for-sale',
                      data: 'Ocean Beach (OB)',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-pacific-beach',
                      url: 'https://www.greatersandiegoareahomes.com/neighborhood-pacific-beach/143923796/pacific-beach',
                      data: 'Pacific Beach',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-point-loma',
                      url: 'https://www.greatersandiegoareahomes.com/point-loma-homes-for-sale',
                      data: 'Point Loma',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-rancho-bernardo',
                      url: 'https://www.greatersandiegoareahomes.com/rancho-bernardo-golf-course-homes',
                      data: 'Rancho Bernardo Golf Course Homes',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-rancho-santa-fe',
                      url: 'https://www.greatersandiegoareahomes.com/rancho-santa-fe-homes-for-sale',
                      data: 'Rancho Santa Fe',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-solana-beach',
                      url: 'https://www.greatersandiegoareahomes.com/solana-beach/156100719/solana-beach',
                      data: 'Solana Beach',
                    },
                    {
                      id: 'on-ciovo-footer-neighborhoods-torrey-hills',
                      url: 'https://www.greatersandiegoareahomes.com/torrey-hills-san-diego/156101296/torrey-hills',
                      data: 'Torrey Hills',
                    },
                  ],
                },
                {
                  id: 'footer-menu-item-trade-in',
                  data: 'EXCLUSIVE TRADE-IN PR...',
                  url: 'http://trade-in.greatersandiegoareahomes.com/',
                },
                {
                  id: 'footer-menu-item-cash-offer',
                  data: 'EXCLUSIVE CASH OFFER PR...',
                  url: 'https://cash-offer-ciovo.netlify.app/',
                },
                {
                  id: 'footer-menu-item-blogs',
                  data: 'BLOGS',
                  url: 'https://www.greatersandiegoareahomes.com/blog',
                },
                {
                  id: 'footer-menu-item-about',
                  data: 'ABOUT',
                  submenuClassName: '',
                  submenuItemsClassName: 'on-ciovo-submenu-items',
                  submenuLabelClassName: 'on-ciovo-submenu-label',
                  submenuItems: [
                    {
                      id: 'on-ciovo-footer-about-us',
                      url: 'https://www.greatersandiegoareahomes.com/about',
                      data: 'About Us',
                    },
                    {
                      id: 'on-ciovo-footer-about-reviews',
                      url: 'https://www.greatersandiegoareahomes.com/reviews',
                      data: 'Reviews',
                    },
                    {
                      id: 'on-ciovo-footer-about-contact-us',
                      url: 'https://www.greatersandiegoareahomes.com/contact',
                      data: 'Contact Us',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-ciovo-footer-third',
      display: true,
      className: 'on-ciovo-footer-section on-ciovo-footer-section-last',
      sectionClassName: 'on-ciovo-footer-section-container-third',
      columns: [
        {
          id: 'on-ciovo-footer-third-col',
          className: 'on-ciovo-footer-privacy',
          style: {},
          items: [
            {
              id: 'ciovo-footer-disclaimer-item-nine',
              type: 'itemContent',
              style: { paddingBottom: '10px' },
              items: [
                {
                  content: 'Made in Phoenix, AZ by your friends at ',
                },
                {
                  url: 'https://chime.me/',
                  content: ' Chime Technologies',
                },
                {
                  content: ',  Inc. Copyright 2022. All Rights Reserved. ',
                },
                {
                  url: 'https://www.greatersandiegoareahomes.com/site/privacy-terms',
                  content: 'Privacy Policy & TOS',
                },
                {
                  content: ' | ',
                },
                {
                  url: 'https://www.greatersandiegoareahomes.com/sitemap',
                  content: 'Sitemap',
                  className: 'on-ciovo-itemc-underline',
                },
                {
                  content: ' | ',
                },
                {
                  url: 'https://www.greatersandiegoareahomes.com/accessibility',
                  content: 'Accessibility',
                  className: 'on-ciovo-itemc-underline',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {
    'tradeIn.hero.className': 'heroClassName',
    'cashOffer.hero.className': 'heroClassName',
  },
  siteData: {
    agency: 'Premier Homes Team',
    agentDescription: 'Top agents in Greater San Diego Area',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/ciovo/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/ciovo/hero-bg.jpg',
    heroClassName: 'on-ciovo-hero',
  },
};
